<template>
    <img :src="getImg" :style="`max-width: ${size}px`" alt="RoboShadow" />
</template>

<script>
    export default {
        props: {
            size: {
                type: Number,
                default: 150
            },
            dark: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                logo150Dark: '/assets/img/logos/roboshadow-logo-150-white.png',
                logo250Dark: '/assets/img/logos/roboshadow-logo-250-white.png',
                logo150Light: '/assets/img/logos/roboshadow-logo-150.png',
                logo250Light: '/assets/img/logos/roboshadow-logo-250.png'
            };
        },
        computed: {
            getImg() {
                // TODO improve - allow us to set the logo for a theme?
                const isRoboBlue = this.$vuetify.theme.themes.className === 'robo-blue-theme';
                if (this.dark || this.$vuetify.theme.dark || isRoboBlue) {
                    if (this.size > 150) {
                        return this.logo250Dark;
                    }
                    return this.logo150Dark;
                }
                if (this.size > 150) {
                    return this.logo250Light;
                }
                return this.logo150Light;
            }
            // imgId() {
            //     if (this.$vuetify.theme.dark) {
            //         if (this.size > 150) {
            //             return 1; // logo250Dark;
            //         }
            //         return 0; // logo150Dark;
            //     }
            //     if (this.size > 150) {
            //         return 3; // logo250Light;
            //     }
            //     return 2; // logo150Light;
            // }
        }
    };
</script>
