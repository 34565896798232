<template>
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-y top open-on-hover>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" @mouseover="onHover">
                <slot></slot>
            </div>
        </template>
        <v-card :max-width="maxWidth" class="pa-3">
            <slot name="hover"></slot>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props: {
            hoverId: {
                type: String,
                default: ''
            },
            maxWidth: {
                type: Number,
                default: 275
            }
        },
        methods: {
            onHover() {
                this.$emit('onHover', this.hoverId);
            }
        }
    };
</script>
