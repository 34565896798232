<template>
    <v-autocomplete
        v-model="model"
        :items="articles"
        :loading="loading"
        :search-input.sync="searchQuery"
        hide-no-data
        hide-selected
        hide-details
        item-text="title"
        item-value="slug"
        label="Search"
        placeholder="Enter a keyword.."
        prepend-inner-icon="mdi-magnify"
        return-object
        clearable
        rounded
        dense
        solo
        flat
        @change="onSelect"
    >
        <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
                <v-list-item-avatar>
                    <v-icon>mdi-file-document-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        data() {
            return {
                searchQuery: '',
                articles: [],
                model: null,
                loading: false
            };
        },
        watch: {
            async searchQuery(searchQuery) {
                if (!searchQuery) {
                    this.articles = [];
                    return;
                }
                this.loading = true;
                this.articles = await this.$content({ deep: true })
                    .where({ slug: { $ne: '_dir' } }) // TODO show dirs - nav to first item
                    .limit(6)
                    .search(searchQuery)
                    .fetch();
                this.loading = false;
            }
        },
        methods: {
            onSelect(article) {
                if (!article) return;
                // this.$router.push({ name: 'help-slug', params: { slug: article.slug } });
                this.$router.push(article.path);
                this.searchQuery = '';
            }
        }
    };
</script>
