<template>
    <v-list>
        <template v-for="menuItem of items">
            <v-list-item
                v-if="!menuItem.children"
                :key="menuItem.path"
                :to="menuItem.path"
                :dense="denseRoot"
                nav
            >
                <v-list-item-icon>
                    <v-icon>{{ menuItem.img }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>

            <v-list-group
                v-else
                :key="menuItem.path"
                :prepend-icon="menuItem.img"
                :value="checkOpenNavItem(menuItem)"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <template v-for="subItem of menuItem.children">
                    <v-list-item
                        v-if="!subItem.children"
                        :key="subItem.path"
                        :to="subItem.path"
                        :dense="dense"
                        nav
                    >
                        <v-list-item-icon>
                            <v-icon>{{ subItem.img }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-group
                        v-else
                        :key="subItem.path"
                        no-action
                        sub-group
                        :value="checkOpenNavItem(subItem)"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="subSubItem in subItem.children"
                            :key="subSubItem.path"
                            :to="subSubItem.path"
                            :dense="dense"
                            link
                        >
                            <v-list-item-title>{{ subSubItem.title }}</v-list-item-title>

                            <v-list-item-icon>
                                <v-icon>{{ subSubItem.img }}</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list-group>
        </template>
    </v-list>
    <!--
    <v-list dense>
        <v-list-item-group>
            <template v-for="menuItem of items">
                <v-list-item
                    v-if="!menuItem.children"
                    :key="menuItem.title"
                    :to="menuItem.path"
                    nav
                >
                    <v-list-item-action>
                        <v-icon>{{ menuItem.img }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group v-else :key="menuItem.title" :prepend-icon="menuItem.img" no-action>
                    <template v-slot:activator>
                        <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                    </template>
                    <template v-for="contentItem of menuItem.children">
                        <v-list-item :key="contentItem.title" :to="contentItem.path" link>
                            <v-list-item-action>
                                <v-icon>{{ contentItem.img }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ contentItem.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
            </template>
        </v-list-item-group>
    </v-list>
    -->
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                default: () => []
            },
            dense: {
                type: Boolean,
                default: false
            },
            denseRoot: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            checkOpenNavItem(item) {
                // console.log(`Checking, slug: ${item.dir} path: ${this.$route.path}`);
                return this.$route.path.includes(item.dir);
            }
        }
    };
</script>
