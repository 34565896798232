/**
 * @fileoverview UserSettingsManager
 *
 **/
export class UserSettingsManager {
    constructor(context) {
        this.$context = context;
        this.$vuetify = context.$vuetify;
        this.$config = context.$config;
        this.THEMES = {
            dark: 'dark',
            light: 'light'
        };
    }

    getUserColourScheme() {
        // TODO
        // return this.$store.getters['settings/getColourScheme'];
    }

    setUserColourScheme(scheme) {
        // TODO this.$store.commit('settings/setColourScheme', scheme);
    }

    getUserTheme() {
        return 'robo-theme'; // TODO return this.$store.getters['settings/getTheme'];
    }

    setUserTheme(className) {
        // TODO this.$store.commit('settings/setTheme', className);
    }

    initialiseTheme() {
        const userScheme = this.getUserColourScheme();
        const userTheme = this.getUserTheme();
        this.$vuetify.theme.dark = userScheme === this.THEMES.dark;
        this.$vuetify.theme.themes.className = userTheme;
        this.setBodyClass(userTheme);
        this.setBodyScrollTheme(this.$vuetify.theme.dark);
    }

    applyTheme(theme) {
        const className = theme.className;
        const dark = theme.dark;
        const light = theme.light;
        // set themes
        Object.keys(dark).forEach((i) => {
            this.$vuetify.theme.themes.dark[i] = dark[i];
        });
        Object.keys(light).forEach((i) => {
            this.$vuetify.theme.themes.light[i] = light[i];
        });
        // also save theme name to disable selection
        this.$vuetify.theme.themes.className = className;
        this.setBodyClass(className);
        this.setUserTheme(className);
    }

    applyColourScheme(isDarkMode) {
        this.$vuetify.theme.dark = isDarkMode;
        const scheme = this.$vuetify.theme.dark ? this.THEMES.dark : this.THEMES.light;
        this.setUserColourScheme(scheme);
        this.setBodyScrollTheme(this.$vuetify.theme.dark);
    }

    setBodyClass(className) {
        // set parent class for custom css theme styling
        document.body.className = className;
    }

    setBodyScrollTheme(isDark) {
        // remove scrollbars
        document.documentElement.style.overflow = 'hidden';
        // trigger reflow so that overflow style is applied
        // TODO ? document.body.clientWidth;
        // change scheme
        document.documentElement.setAttribute('data-color-scheme', isDark ? 'dark' : 'light');
        // remove overflow style, which will bring back the scrollbar with the correct scheme
        document.documentElement.style.overflow = '';
    }
}
