<template>
    <v-navigation-drawer v-model="drawerState" app clipped>
        <v-sheet class="text-center py-3 surfaceOnCardLight">
            <v-btn v-if="$vuetify.breakpoint.smAndDown" plain to="/">
                <LogoRoboShadow />
            </v-btn>
        </v-sheet>
        <Menu />
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Menu from '~/components/app/help/Menu';
    export default {
        components: { Menu },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                drawerState: false
            };
        },
        computed: {
            ...mapGetters(['orgId'])
        },
        watch: {
            value(newValue) {
                this.drawerState = newValue;
            },
            drawerState(newValue) {
                this.$emit('input', newValue);
            }
        }
    };
</script>
