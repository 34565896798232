<template>
    <v-tooltip top>
        <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
                <v-btn
                    :x-small="xSmall"
                    :small="small"
                    :large="large"
                    :x-large="xLarge"
                    text
                    :color="color"
                    class="px-1"
                    @click="onNavigate"
                >
                    <v-img :max-width="imageSize" class="mx-none" :src="imageSrc" />
                    <span>&nbsp;Robo Guard</span>
                </v-btn>
            </span>
        </template>
        <div class="pb-2">
            <h5>
                <v-img
                    max-width="24"
                    class="mx-none"
                    src="/assets/img/logos/RoboGuard-Mask-Shield-48-White.png"
                    style="display: inline-block; vertical-align: middle"
                />
                Robo Guard
            </h5>
            <p>Premium protection for professionals.</p>
            <div>
                <v-icon small>mdi-timer-settings-outline</v-icon>
                Scan Scheduling
            </div>
            <div>
                <v-icon small>mdi-email-seal-outline</v-icon>
                Constant Monitoring
            </div>
            <div>
                <v-icon small>mdi-calendar-star</v-icon>
                Regular Reporting
            </div>
        </div>
    </v-tooltip>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'primary'
            },
            xSmall: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            xLarge: {
                type: Boolean,
                default: false
            }
            // value: {
            //     type: [String, Number],
            //     default: ''
            // },
            // label: {
            //     type: String,
            //     default: ''
            // },
            // valueLabel: {
            //     type: String,
            //     default: ''
            // },
            // size: {
            //     type: [String, Number],
            //     default: 14
            // }
        },
        computed: {
            imageSize() {
                if (this.xSmall) {
                    return 16;
                } else if (this.small) {
                    return 18;
                } else if (this.large) {
                    return 22;
                } else if (this.xLarge) {
                    return 24;
                }
                return 20;
            },
            imageSrc() {
                if (this.color === 'primary') {
                    return '/assets/img/logos/RoboGuard-Mask-Shield-48-Blue.png';
                }
                return '/assets/img/logos/RoboGuard-Mask-Shield-48-White.png';
            }
        },
        methods: {
            async onNavigate() {
                await this.$router.push('/account/plan');
            }
        }
    };
</script>
