import portDataset from '~/static/assets/datasets/service-names-port-numbers.json';

export class PortDataService {
    constructor(context) {
        this.$context = context;
    }

    getPortData(portNumber) {
        let portTcp = null;
        let portUdp = null;

        portDataset.forEach((item) => {
            if (item['Port Number'] === `${portNumber}`) {
                if (item['Transport Protocol'] === 'tcp') {
                    portTcp = item;
                } else if (item['Transport Protocol'] === 'udp') {
                    portUdp = item;
                }
            }
        });

        return {
            portTcp,
            portUdp
        };
    }
}
