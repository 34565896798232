/**
 * https://redfern.dev/articles/adding-social-media-seo-meta-data-using-nuxt-content/
 * https://gist.github.com/garethredfern/b79c3f95ceb82f213e2d68ce7b5fae5b
 */
import constants from './constants';

// DEFAULTS
const type = 'website';
const url = constants.SITE_URL;
const title = constants.SITE_NAME;
const description = constants.SITE_STRAP_LINE;
const mainImage = constants.SITE_LOGO;

export default (meta) => {
    return [
        {
            hid: 'description',
            name: 'description',
            content: (meta && meta.description) || description
        },
        {
            hid: 'og:type',
            property: 'og:type',
            content: (meta && meta.type) || type
        },
        {
            hid: 'og:url',
            property: 'og:url',
            content: (meta && meta.url) || url
        },
        {
            hid: 'og:title',
            property: 'og:title',
            content: (meta && meta.title) || title
        },
        {
            hid: 'og:description',
            property: 'og:description',
            content: (meta && meta.description) || description
        },
        {
            hid: 'og:image',
            property: 'og:image',
            content: (meta && meta.mainImage) || mainImage
        },
        {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: (meta && meta.url) || url
        },
        {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: (meta && meta.title) || title
        },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: (meta && meta.description) || description
        },
        {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: (meta && meta.mainImage) || mainImage
        }
    ];
};
