<template>
    <MenuTree :items="articles" />
</template>
<script>
    import MenuTree from '~/components/app/help/MenuTree';
    /**
     * article: {
     *   body: Object
     *   excerpt: Object
     *   title: "Introduction"
     *   description: "Learn how to use @nuxt/content."
     *   dir: "/"
     *   extension: ".md"
     *   path: "/index"
     *   slug: "index"
     *   toc: Array
     *   createdAt: DateTime
     *   updatedAt: DateTime
     *   img: ''
     */
    export default {
        components: { MenuTree },
        props: {
            items: {
                type: Array,
                default: () => []
            }
        },
        data() {
            const menuIndex = ['get-started/_dir', 'guide/_dir', 'ports/_dir'];
            // TODO if (this.$jsHelper.isTestUser()) {
            // menuIndex.push('changelog');
            // menuIndex.push('publishing');
            // }
            return {
                menuIndex,
                articles: []
            };
        },
        async created() {
            for (const menuPath of this.menuIndex) {
                const article = await this.$content(menuPath).fetch();
                await this.getArticleChildren(article);
                this.articles.push(article);
            }
        },
        methods: {
            // async getArticleByPath(path) {
            //     const article = await this.$content(path).fetch();
            //     await this.getArticleChildren(article);
            //     return article;
            // },
            async getArticleChildren(article) {
                if (article.slug === '_dir') {
                    // article.children = await this.$content(article.path).fetch();
                    const children = await this.$content(article.dir.substring(1), { deep: true })
                        .sortBy('order')
                        .fetch();
                    if (!children) return;
                    article.children = [];
                    for (const child of children) {
                        if (child.path === article.path) continue; // same folder

                        // console.log(`${article.path}, ${child.path}`);

                        // NOTE we want to include dirs and immediate children
                        // - but exclude sub children (vulnerability-scanner/introduction)
                        // EXAMPLE
                        //   /guide/_dir, /guide/introduction
                        //   /guide/_dir, /guide/vulnerability-scanner/introduction
                        //   /guide/_dir, /guide/vulnerability-scanner/_dir

                        if (child.slug !== '_dir') {
                            const subPaths = child.path.replace(article.dir, '').split('/');
                            if (subPaths.length > 2) continue;
                        }

                        article.children.push(child);
                        if (child.slug === '_dir') {
                            await this.getArticleChildren(child);
                        }
                    }
                }
            }
        }
    };
</script>
