// import { md5 } from 'request/lib/helpers';

/**
 * @fileoverview JsHelper
 *
 * Javascript general helper for functions with no home
 */
export class JsHelper {
    constructor(context) {
        this.$context = context;
    }

    objectHasProperty(object, propertyName) {
        return Object.prototype.hasOwnProperty.call(object, propertyName);
    }

    /**
     * Truncates a string on the sentence, word and then character.
     */
    truncateLongString(str, n, splitSentences = true) {
        if (str.length <= n) {
            return str;
        }
        const sentenceStr = str.substr(0, str.indexOf('.'));
        if (splitSentences && sentenceStr.length <= n) {
            return sentenceStr;
        }

        const subString = str.substr(0, n - 1); // reduce to max size
        const wordString = subString.substr(0, subString.lastIndexOf(' '));

        if (wordString.trim().length > 0 && wordString.trim().length <= n) {
            return wordString + '..';
        }

        return subString + '..';
    }

    /**
     * Truncates a string by words and then characters.
     */
    truncateString(str, n, splitWords = false) {
        if (!str || str.length <= n) {
            return str;
        }

        const subString = str.substr(0, n - 1); // reduce to max size

        if (splitWords) {
            const wordString = subString.substr(0, subString.lastIndexOf(' '));
            if (wordString.trim().length > 0 && wordString.trim().length <= n) {
                return wordString + '..';
            }
        }

        return subString + '..';
    }

    /**
     * Truncates a string by words and then characters.
     */
    truncateStringRev(str, n, ellipses = '..', splitWords = false) {
        if (!str || str.length <= n) {
            return str;
        }

        const subString = str.substr(str.length - n - 1); // reduce to max size

        if (splitWords) {
            const wordString = subString.substr(subString.lastIndexOf(' '));
            if (wordString.trim().length > 0 && wordString.trim().length <= n) {
                return wordString + ellipses;
            }
        }

        return subString + ellipses;
    }

    /**
     * Returns a new colour for each data value, from the colour range.
     * Used to show the increasing severity of an incrementally increasing range of numbers.
     * eg [1,...,100] = [yellow,...,red]
     *
     * DEBUG
     * console.log(`noOfValues ${noOfValues} noOfColours ${noOfColours} interval ${interval}`);
     * console.log(intervalsDebug);
     * console.log(returnColours);
     */
    getColoursFromNoOfValues(values, colours) {
        const returnColours = [];
        const intervalsDebug = [];
        const noOfValues = values.length;
        const noOfColours = colours.length;
        const interval = noOfColours / noOfValues;
        for (let i = 0; i < noOfValues; i++) {
            const intervalIdx = Math.ceil(i * interval);
            intervalsDebug.push(intervalIdx);
            returnColours.push(colours[intervalIdx]);
        }
        return returnColours;
    }

    /**
     * Returns a new colour for the percent of each data value, from the colour range.
     * Used to show the difference between the smaller and larger numbers.
     * eg [10,..,50] = [1%,...,100%] = [yellow,...,red]
     *
     * DEBUG
     * console.log(`maxVal ${maxVal} minVal ${minVal} interval ${interval}`);
     * console.log(intervalsDebug);
     * console.log(returnColours);
     * console.log(values);
     */
    getColoursFromPercentOfEachValue(values, colours) {
        const returnColours = [];
        const intervalsDebug = [];
        const maxVal = Math.max(...values);
        const minVal = Math.min(...values);
        const interval = (maxVal - minVal) / colours.length;
        for (const value of values) {
            const diffFromMin = value === minVal ? 0.1 : value - minVal;
            let intervalIdx = Math.floor(diffFromMin / interval);
            if (intervalIdx >= colours.length) {
                intervalIdx = colours.length - 1;
            }
            intervalsDebug.push(intervalIdx);
            returnColours.push(colours[intervalIdx]);
        }
        return returnColours;
    }

    /**
     * DEBUG
     * console.log(`value:${value} total:${total} percent:${percent} `);
     */
    getColourFromPercentOfValue(value, total, colours) {
        let percent = Math.round((value / total) * 100);
        if (percent > 100) {
            percent = 100;
        }
        return this.getColourFromPercent(percent, colours);
    }

    /**
     * DEBUG
     * console.log(
     *      `percent:${percent} colours:${colours.length} interval:${interval} colourIdx:${colourIdx} `
     *  );
     */
    getColourFromPercent(percent, colours) {
        if (percent === 100) return colours[colours.length - 1];
        if (percent === 0) return colours[0];
        const interval = Math.round(100 / colours.length);
        const colourIdx = Math.floor(percent / interval);
        return colours[colourIdx];
    }

    /**
     * rounds and returns a float
     */
    roundDecimal(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    /**
     * debounces a function
     * https://stackoverflow.com/questions/42199956/how-to-implement-debounce-in-vue2
     */
    debounce(fn, delay) {
        let timeoutID = null;
        return function () {
            clearTimeout(timeoutID);
            const args = arguments;
            const that = this;
            timeoutID = setTimeout(function () {
                fn.apply(that, args);
            }, delay);
        };
    }

    getUserProviderId(user) {
        if (!user || !user.providerData || user.providerData.length === 0) {
            return 'unknown';
        }
        return user.providerData[0].providerId;
    }

    uniqueFilter(value, index, self) {
        return self.indexOf(value) === index;
    }

    // Source: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    // https://github.com/ajerez/vue-mobile-detection
    detectMobileOrTablet() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    hasDateTime(isoString) {
        return !(
            !isoString ||
            isoString === '0001-01-01T00:00:00.0000000+00:00' ||
            isoString === '0001-01-01T00:00:00+00:00'
        );
    }

    getIsoStringToLocaleString(isoString) {
        if (
            !isoString ||
            isoString === '0001-01-01T00:00:00.0000000+00:00' ||
            isoString === '0001-01-01T00:00:00+00:00'
        ) {
            return '-';
        }
        const date = this.$context.$dateFns.parseISO(isoString);
        if (date.getTime() === new Date(0).getTime()) {
            return '-';
        }
        return date.toLocaleString();
    }

    unixToLocalDateTime(unixTimeStamp) {
        try {
            if (!unixTimeStamp) {
                return '-';
            }
            // return this.$context.$dateFns.fromUnixTime(unixTimeStamp);
            return new Date(parseInt(unixTimeStamp)).toLocaleString();
        } catch (ex) {
            return '-';
        }
    }

    unixToLocalDate(unixTimeStamp) {
        try {
            if (!unixTimeStamp) {
                return '-';
            }
            // return this.$context.$dateFns.fromUnixTime(unixTimeStamp);
            return new Date(parseInt(unixTimeStamp)).toLocaleDateString();
        } catch (ex) {
            return '-';
        }
    }

    unixToDateTime(unixTimeStamp) {
        try {
            const milliseconds = unixTimeStamp * 1000;
            const date = new Date(milliseconds);

            if (unixTimeStamp <= 0) return null;
            else return date;
        } catch (ex) {
            return null;
        }
    }

    unixToDateTimeString(unixTimeStamp) {
        try {
            const milliseconds = unixTimeStamp * 1000;
            const date = new Date(milliseconds);

            if (unixTimeStamp <= 0) return '-';
            else return date.toLocaleString();
        } catch (ex) {
            return '-';
        }
    }

    isWithinPastDays(dateTimeToCompare, pastDays) {
        if (dateTimeToCompare instanceof Date) {
            const dateTimeMilliseconds = dateTimeToCompare.getTime();
            const currentMilliseconds = new Date().getTime();
            const daysDifference =
                (currentMilliseconds - dateTimeMilliseconds) / (1000 * 60 * 60 * 24);
            return daysDifference <= pastDays;
        } else {
            const dateTimeToCompareDate = new Date(dateTimeToCompare);
            if (dateTimeToCompareDate.toString() !== 'Invalid Date') {
                const dateTimeMilliseconds = dateTimeToCompareDate.getTime();
                const currentMilliseconds = new Date().getTime();
                const daysDifference =
                    (currentMilliseconds - dateTimeMilliseconds) / (1000 * 60 * 60 * 24);
                return daysDifference <= pastDays;
            } // TODO else
        }
    }

    getSafeId(id) {
        return id.replace(/[ #\\/]/g, '-');
    }

    navToDevices() {
        return this.$context.app.router.push(`/devices`);
    }

    getFileSafeDate(date) {
        return `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}-${date.getHours()}-${date.getMinutes()}`;
    }

    navToAgents() {
        return this.$context.app.router.push(`/devices?agent=true`);
    }

    navToAgentless() {
        return this.$context.app.router.push(`/devices?agentless=true&hideNonWindows=true`);
    }

    navToCveTable() {
        return this.$context.app.router.push(`/reports/cves?default=true#cves`);
    }

    navToCve(cveId) {
        const safeName = this.$context.$jsHelper.getSafeId(cveId);
        return this.$context.app.router.push(`/reports/cves#${safeName}`);
    }

    navToCveProduct(appName, groupApps = false) {
        const safeName = this.$context.$jsHelper.getSafeId(appName);
        return this.$context.app.router.push(`/reports/cves?groupApps=${groupApps}#${safeName}`);
    }

    navToCveDevice(machineName) {
        const safeName = this.$context.$jsHelper.getSafeId(machineName);
        return this.$context.app.router.push(`/reports/cves#${safeName}`);
    }

    navToCveDeviceTable() {
        return this.$context.app.router.push(`/reports/cves?default=true#devices`);
    }

    navToServersCveDeviceTable() {
        return this.$context.app.router.push(`/reports/cves?deviceType=Server#devices`);
    }

    navToWorkstationsCveDeviceTable() {
        return this.$context.app.router.push(`/reports/cves?deviceType=Workstation#devices`);
    }

    navToCveDeviceSeverity(severity) {
        const safeName = this.$context.$jsHelper.getSafeId(severity);
        return this.$context.app.router.push(`/reports/cves?deviceSeverity=${safeName}#devices`);
    }

    navToCveVulnerableDevices() {
        return this.$context.app.router.push(`/reports/cves?vulnerableDevices=true#devices`);
    }

    navToCveVulnerableApps() {
        return this.$context.app.router.push(`/reports/cves?vulnerableApps=true#applications`);
    }

    navToCveCriticalCves() {
        return this.$context.app.router.push(`/reports/cves?criticalCves=true#cves`);
    }

    navToDefenderDevices() {
        return this.$context.app.router.push(`/reports/antivirus?default=true#devices`);
    }

    navToDefenderThreats() {
        return this.$context.app.router.push(`/reports/antivirus?default=true#threats`);
    }

    navToDefenderNotUpdated() {
        return this.$context.app.router.push(`/reports/antivirus?notUpdated=true#devices`);
    }

    navToDefenderDisabled() {
        return this.$context.app.router.push(`/reports/antivirus?disabled=true#devices`);
    }

    navToDefenderNoRealtime() {
        return this.$context.app.router.push(
            `/reports/antivirus?noRealtimeProtection=true#defenders`
        );
    }

    navToDefenderNoOnAccess() {
        return this.$context.app.router.push(
            `/reports/antivirus?noOnAccessProtection=true#defenders`
        );
    }

    navToThirdPartyAv() {
        return this.$context.app.router.push(`/reports/antivirus#thirdPartyAvs`);
    }

    navToThirdPartyAvNoData() {
        return this.$context.app.router.push(
            `/reports/antivirus?thirdPartyAvNoData=true#thirdPartyAvs`
        );
    }

    navToThirdPartyAvNotEnabled() {
        return this.$context.app.router.push(
            `/reports/antivirus?thirdPartyAvNotEnabled=true#thirdPartyAvs`
        );
    }

    navToThirdPartyAvNotUpdated() {
        return this.$context.app.router.push(
            `/reports/antivirus?thirdPartyAvNotUpdated=true#thirdPartyAvs`
        );
    }

    navToDefenderNoFirewall() {
        return this.$context.app.router.push(`/reports/antivirus?firewallDisabled=true#devices`);
    }

    navToNoRansomware() {
        return this.$context.app.router.push(
            `/reports/antivirus?ransomwareDisabled=true#defenders`
        );
    }

    navToUpdatesDevices() {
        return this.$context.app.router.push(`/reports/windows-updates?default=true`);
    }

    navToUpdatesLatest() {
        return this.$context.app.router.push(`/reports/windows-updates?default=true`);
    }

    navToUpdatesCritical() {
        return this.$context.app.router.push(`/reports/windows-updates?criticalUpdates=true`);
    }

    navToUpdatesSecurity() {
        return this.$context.app.router.push(`/reports/windows-updates?securityUpdates=true`);
    }

    navToRollUpUpdates() {
        return this.$context.app.router.push(`/reports/windows-updates?rollUpUpdates=true`);
    }

    navToDriverUpdates() {
        return this.$context.app.router.push(`/reports/windows-updates?driverUpdates=true`);
    }

    navToUpdatesRebootNeeded() {
        return this.$context.app.router.push(`/reports/windows-updates?rebootNeeded=true`);
    }

    navToDisksServersUnencrypted() {
        return this.$context.app.router.push(
            `/reports/data?servers=true&notEncrypted=true#devices`
        );
    }

    navToDisksWorkstationsUnencrypted() {
        return this.$context.app.router.push(
            `/reports/data?workstations=true&notEncrypted=true#devices`
        );
    }

    navToDisksLaptopsUnencrypted() {
        return this.$context.app.router.push(
            `/reports/data?notebooksAndLaptopsOnly=true&notEncrypted=true#devices`
        );
    }

    navToDisksDevicesUnencrypted() {
        return this.$context.app.router.push(`/reports/data?notEncrypted=true#devices`);
    }

    navToDisksDevicesLowDiskSpace() {
        return this.$context.app.router.push(`/reports/data?lowDiskSpace=true#devices`);
    }

    navToUsbDevices() {
        return this.$context.app.router.push(`/reports/data?removable=true#devices`);
    }

    navToDisksDevicesHasShares() {
        return this.$context.app.router.push(`/reports/data?hasShares=true#devices`);
    }

    navToDiskDevices() {
        return this.$context.app.router.push(`/reports/data?default=true#devices`);
    }

    navToHardwareDevices() {
        return this.$context.app.router.push(`/reports/hardware?default=true`);
    }

    navToHardwareServers() {
        return this.$context.app.router.push(`/reports/hardware?servers=true`);
    }

    navToHardwareWorkstations() {
        return this.$context.app.router.push(`/reports/hardware?workstations=true`);
    }

    navToHardwareEolOs() {
        return this.$context.app.router.push(`/reports/hardware?eolOs=true`);
    }

    navToSoftwareApplications() {
        return this.$context.app.router.push(`/reports/software?default=true`);
    }

    navToSoftwareNoCommonApps() {
        return this.$context.app.router.push(`/reports/software?nonCommonApps=true`);
    }

    navToSoftwareNoMicrosoftApps() {
        return this.$context.app.router.push(`/reports/software?nonMicrosoftApps=true`);
    }

    navToDarkWebPasswords() {
        return this.$context.app.router.push(`/reports/dark-web?isPassword=true`);
    }

    navToDarkWebMalware() {
        return this.$context.app.router.push(`/reports/dark-web?isMalware=true`);
    }

    navToDarkWebSpamList() {
        return this.$context.app.router.push(`/reports/dark-web?isSpamList=true`);
    }

    navToDarkWebSensitive() {
        return this.$context.app.router.push(`/reports/dark-web?isSensitive=true`);
    }

    navToDarkWebThreats() {
        return this.$context.app.router.push(`/reports/dark-web?default=true`);
    }

    navToCveDevicesOpenTcpPorts() {
        return this.$context.app.router.push('/vulnerability-scanner2?openTcpPorts=true#devices');
    }

    navToCveDevicesOpenUdpPorts() {
        return this.$context.app.router.push('/vulnerability-scanner2?openUdpPorts=true#devices');
    }

    navToCveDevicesOwaspAlerts() {
        return this.$context.app.router.push('/vulnerability-scanner2?owaspAlerts=true#devices');
    }

    navToCveDevicesSslAlerts() {
        return this.$context.app.router.push('/vulnerability-scanner2?sslAlerts=true#devices');
    }

    navToCveDevicesWebsites() {
        return this.$context.app.router.push('/vulnerability-scanner2?websites=true#devices');
    }

    navToCveDevicesApplications() {
        return this.$context.app.router.push('/vulnerability-scanner2?applications=true#devices');
    }

    navToCveDevicesCves() {
        return this.$context.app.router.push('/vulnerability-scanner2?cves=true#devices');
    }

    navToCveDevicesCvss() {
        return this.$context.app.router.push('/vulnerability-scanner2?cvss=true#devices');
    }

    navToCveDevicesCvesBanners() {
        return this.$context.app.router.push('/vulnerability-scanner2?cvesBanner=true#devices');
    }

    navToCveDevicesCvesHeaders() {
        return this.$context.app.router.push('/vulnerability-scanner2?cvesHeader=true#devices');
    }

    navMsDashboardMembersWithMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaEnabled=true&enabledAccounts=true&members=true`
        );
    }

    navMsDashboardGuestsWithMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaEnabled=true&enabledAccounts=true&guests=true`
        );
    }

    navMsDashboardGlobalAdminsWithMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaEnabled=true&globalAdministrators=true`
        );
    }

    navMsDashboardMembersWithoutMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaDisabled=true&enabledAccounts=true&members=true`
        );
    }

    navMsDashboardGuestsWithoutMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaDisabled=true&enabledAccounts=true&guests=true`
        );
    }

    navMsDashboardGlobalAdminsWithoutMfa() {
        return this.$context.app.router.push(
            `/cloud/azure-mfa?mfaDisabled=true&globalAdministrators=true`
        );
    }

    navMsDashboardEnabledUsers() {
        return this.$context.app.router.push(`/cloud/azure-mfa?enabledAccounts=true`);
    }

    navMsDashboardDisabledUsers() {
        return this.$context.app.router.push(`/cloud/azure-mfa?disabledAccounts=true`);
    }

    navCrossAccountSyncOnPremSyncIssues() {
        return this.$context.app.router.push(`/cross-account/ad-sync?onPremIssue=true`);
    }

    navCrossAccountSyncAzureSyncIssues() {
        return this.$context.app.router.push(`/cross-account/ad-sync?azureIssue=true`);
    }

    navCrossAccountSyncNotSyncing() {
        return this.$context.app.router.push(`/cross-account/ad-sync?notSyncing=true`);
    }

    navCrossAccountSyncTotalAgents() {
        return this.$context.app.router.push(`/cross-account/ad-sync`);
    }

    navCrossAccountSyncAgentlessMachines() {
        return this.$context.app.router.push(`/cross-account/ad-sync?hasAgentlessMachines=true`);
    }
}
