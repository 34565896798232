/**
 @fileoverview Global app constants
 */

/**
 * DEFAULT remote config keys & values
 *  Used if - no value is set remotely, or remote config isnt loaded yet
 *
 * IMPORTANT
 *  these keys MUST match the same values setup in remote config!
 *
 * 2 types of config values:
 *  portal_feature: boolean
 *  portal_variant: string
 *
 */
const PORTAL_TEST_USER = 'portal_test_user';
const PORTAL_FEATURE_RMM_KEY = 'portal_feature_rmm';
const PORTAL_FEATURE_VSCAN2_KEY = 'portal_feature_vscan2';
const PORTAL_FEATURE_WELCOME_PAGE_KEY = 'portal_feature_welcome_page';
const PORTAL_VARIANT_PLAN_PURCHASE_KEY = 'portal_variant_plan_purchase';

// DEFAULT remote configuration
const remoteConfigKeys = {
    [PORTAL_TEST_USER]: false,
    [PORTAL_FEATURE_RMM_KEY]: false,
    [PORTAL_FEATURE_VSCAN2_KEY]: false,
    [PORTAL_FEATURE_WELCOME_PAGE_KEY]: false,
    [PORTAL_VARIANT_PLAN_PURCHASE_KEY]: ''
};

export default {
    remoteConfigKeys,
    TWITTER_ID: '@Robo_Shadow',
    SITE_NAME: 'Robo Shadow Portal',
    SITE_STRAP_LINE: 'Cyber Security, Demystified.',
    SITE_URL: 'https://portal.roboshadow.com',
    SITE_LOGO: 'https://portal.roboshadow.com/assets/img/logos/roboshadow-logo-250.png',
    PORTAL_TEST_USER,
    PORTAL_FEATURE_RMM_KEY,
    PORTAL_FEATURE_VSCAN2_KEY,
    PORTAL_FEATURE_WELCOME_PAGE_KEY,
    PORTAL_VARIANT_PLAN_PURCHASE_KEY
};
