import Vue from 'vue';

import { JsHelper } from './services/JsHelper';
import { UserSettingsManager } from '~/plugins/services/UserSettingsManager';

// import AppTopBar from '~/components/app/help/AppTopBar';
// import AppMenuMain from '~/components/app/help/AppMenuMain';
// import AppBreadcrumbs from '~/components/app/help/AppBreadcrumbs';
// import AppNavDrawer from '~/components/app/help/AppNavDrawer';
import AppFooter from '~/components/app/AppFooter';
import AppSnackBar from '~/components/widgets/AppSnackBar';
import AppToolTip from '~/components/widgets/AppToolTip';
import IconMachineType from '~/components/widgets/IconMachineType';
import IconDiskType from '~/components/widgets/IconDiskType';
import IconThreatType from '~/components/widgets/IconThreatType';
import IconAdSyncStatus from '~/components/widgets/IconAdSyncStatus';
import IconScanType from '~/components/widgets/IconScanType';
import LogoRoboShadow from '~/components/LogoRoboShadow';
import LogoRoboGuard from '~/components/widgets/LogoRoboGuard';
import IconRoboGuard from '~/components/widgets/icons/IconRoboGuard';
import constants from '~/plugins/constants';
import appMeta from '~/plugins/app-meta';
import { PortDataService } from '~/plugins/services/PortDataService';

// Vue.component('AppTopBar', AppTopBar);
// Vue.component('AppMenuMain', AppMenuMain);
// Vue.component('AppBreadcrumbs', AppBreadcrumbs);
// Vue.component('AppNavDrawer', AppNavDrawer);
Vue.component('AppFooter', AppFooter);
Vue.component('AppSnackBar', AppSnackBar);
Vue.component('AppToolTip', AppToolTip);
Vue.component('IconMachineType', IconMachineType);
Vue.component('IconDiskType', IconDiskType);
Vue.component('IconThreatType', IconThreatType);
Vue.component('IconAdSyncStatus', IconAdSyncStatus);
Vue.component('IconScanType', IconScanType);
Vue.component('LogoRoboShadow', LogoRoboShadow);
Vue.component('LogoRoboGuard', LogoRoboGuard);
Vue.component('IconRoboGuard', IconRoboGuard);

const appMetaData = appMeta();

let appSnackBar = null;
const snackBarHelper = {
    getSnackBar() {
        return appSnackBar;
    }
};

// VUE plugin
const appUiPlugin = {
    install(Vue, options) {
        // APP SNACK-BAR
        Vue.prototype.$setAppSnackBar = function (snackBarVue) {
            appSnackBar = snackBarVue;
        };
        Vue.prototype.$showSnackBarInfo = function (message) {
            if (!appSnackBar) return;
            appSnackBar.showInfo(message);
        };
        Vue.prototype.$showSnackBarError = function (errOrMessage, friendlyMessage = null) {
            if (!appSnackBar) return;
            appSnackBar.showError(errOrMessage, friendlyMessage);
        };
        Vue.prototype.$getPageHead = function (
            title,
            description = 'Cyber Security, Demystified.'
        ) {
            return {
                title: `${title} - RoboShadow Portal`,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: description
                    }
                ]
            };
        };
        Vue.prototype.$getPageHeadTwitter = function (
            title,
            description = 'Cyber Security, Demystified.'
        ) {
            return {
                htmlAttrs: {
                    lang: 'en-GB'
                },
                title: constants.SITE_NAME,
                meta: [
                    ...appMetaData,
                    { charset: 'utf-8' },
                    { name: 'HandheldFriendly', content: 'True' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { property: 'og:site_name', content: title },
                    {
                        hid: 'description',
                        name: 'description',
                        content: description
                    },
                    { property: 'og:image:width', content: '250' },
                    { property: 'og:image:height', content: '59' },
                    { name: 'twitter:site', content: constants.TWITTER_ID },
                    { name: 'twitter:card', content: 'summary' }
                ],
                link: [
                    {
                        rel: 'icon',
                        type: 'image/x-icon',
                        href: '/favicon.ico'
                    },
                    {
                        hid: 'canonical',
                        rel: 'canonical',
                        href: process.env.BASE_URL
                    }
                ]
            };
        };
        Vue.prototype.$configureGoogleTag = function (inst) {
            const debugMode = false; // inst.$userSettingsManager.getGtagDebugMode();
            const appVersion = `${inst.$config.versionNumber}.${inst.$config.buildNumber}`;
            const config = {
                app_version: appVersion,
                debug_mode: debugMode
            };
            inst.$gtag('config', inst.$config.googleTagId, config);
        };
    }
};
Vue.use(appUiPlugin);

// NUXT plugin init
export default (context, inject) => {
    const jsHelper = new JsHelper(context);
    const portDataService = new PortDataService(context);

    const userSettingsManager = new UserSettingsManager(context);

    // const notificationService = new NotificationService(context);
    // const notificationManager = new NotificationManager(context, notificationService, jsHelper);

    inject('jsHelper', jsHelper);
    inject('portDataService', portDataService);
    inject('snackBarHelper', snackBarHelper);
    // inject('notificationService', notificationService);
    // inject('notificationManager', notificationManager);

    inject('userSettingsManager', userSettingsManager);
};
