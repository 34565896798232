<template>
    <v-tooltip top>
        <template #activator="{ on, attrs }">
            <v-icon
                :color="iconColour"
                :size="size"
                :small="small"
                :x-small="xSmall"
                :large="large"
                :x-large="xLarge"
                v-bind="attrs"
                v-on="on"
            >
                {{ iconImage }}
            </v-icon>
        </template>
        <span>{{ label }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: null
            },
            // color: {
            //     type: String,
            //     default: null
            // },
            xSmall: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            xLarge: {
                type: Boolean,
                default: false
            },
            size: {
                type: [Number, String],
                default: null
            }
        },
        computed: {
            iconImage() {
                return this.$deviceService.getSyncStatusIcon(this.value);
            },
            iconColour() {
                return this.$deviceService.getSyncStatusColor(this.value);
            },
            label() {
                return this.value;
            }
        }
    };
</script>
