<template>
    <v-app id="inspire">
        <v-app-bar app :show-drawer-button="$vuetify.breakpoint.lgAndDown">
            <v-container class="py-0 fill-height">
                <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer" />
                <v-btn plain to="/">
                    <img
                        v-if="$vuetify.breakpoint.smAndDown"
                        src="/assets/img/roboshadow-icon-toolbar.png"
                        style="max-width: 36px"
                        alt="Robo Shadow Help"
                    />
                    <LogoRoboShadow v-else />
                </v-btn>
                <div class="hidden-md-and-down">
                    <v-btn text to="/get-started/introduction">Get Started</v-btn>
                    <v-btn text to="/guide/introduction">Guide</v-btn>
                    <v-btn text to="/ports/introduction">Ports</v-btn>
                    <v-btn text>API</v-btn>
                    <!--
                    <v-btn text to="/changelog">Changelog</v-btn>
                    <v-btn text to="/case-studies">Case Studies?</v-btn>
                    <v-btn v-if="$firebaseConfig.isTestUser()" to="/playground" text>
                        Playground
                    </v-btn>
                    -->
                </div>
                <v-spacer />
                <div
                    style="display: inline-block"
                    :style="'max-width:' + ($vuetify.breakpoint.xsOnly ? '150px' : '280px')"
                >
                    <SearchInput />
                </div>

                &nbsp;
                <v-btn icon @click="onChangeScheme">
                    <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
                    <v-icon v-else>mdi-weather-night</v-icon>
                </v-btn>
                <v-btn icon link :href="`https://twitter.com/${twitterId}`" target="_blank">
                    <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <!--
                <v-btn
                    color="primary"
                    text
                    href="mailto:hello@roboshadow.com"
                    class="hidden-sm-and-down mr-2"
                >
                    Get Support
                </v-btn>
                <v-btn color="success" to="/register" class="hidden-sm-and-down mr-2" outlined>
                    FREE Account
                </v-btn>
                <v-btn color="success">Log In</v-btn>
                -->
            </v-container>
        </v-app-bar>
        <NavDrawer v-if="$vuetify.breakpoint.mdAndDown" v-model="drawer" />
        <v-main>
            <v-container>
                <v-responsive>
                    <nuxt />
                </v-responsive>
            </v-container>
        </v-main>
        <AppFooter />
    </v-app>
</template>

<script>
    import constants from '~/plugins/constants';
    import SearchInput from '~/components/app/help/SearchInput';
    import NavDrawer from '~/components/app/help/NavDrawer';
    export default {
        components: { SearchInput, NavDrawer },
        data() {
            return {
                drawer: false,
                twitterId: constants.TWITTER_ID
            };
        },
        mounted() {
            this.$configureGoogleTag(this);
            if (this.$config.isInProduction) this.$hotjarTrackingService.addHotjarScript();
            if (this.$config.isInProduction) this.$linkedInTracking.addLinkedInScript();
        },
        created() {
            this.$userSettingsManager.initialiseTheme();
        },
        methods: {
            onChangeScheme() {
                this.$userSettingsManager.applyColourScheme(!this.$vuetify.theme.dark);
            }
        },
        head() {
            return this.$getPageHeadTwitter(constants.SITE_NAME, constants.SITE_STRAP_LINE);
        }
    };
</script>

<noscript>
    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=496388&fmt=gif" />
</noscript>
