<template>
    <v-snackbar v-model="snackBar" :timeout="snackTimeout">
        <v-icon v-if="snackIcon" class="pr-3" :color="snackColour">
            {{ snackIcon }}
        </v-icon>
        <span>{{ snackText }}</span>
        <template #[`action`]="{ attrs }">
            <v-btn small text v-bind="attrs" @click="snackBar = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        props: {
            colour: {
                type: String,
                default: ''
            },
            timeout: {
                type: Number,
                default: 3000
            },
            text: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                snackBar: false,
                snackText: this.text,
                snackTimeout: this.timeout,
                snackColour: this.colour,
                snackIcon: this.icon,
                defaultErrorMessage: 'Woops, there was an unexpected problem.'
            };
        },
        created() {
            this.$setAppSnackBar(this);
        },
        methods: {
            showInfo(message) {
                this.show(message, 'success', 'mdi-information-outline');
                this.$nuxt.$firebaseEvents.toastInfo(message);
            },
            showError(errOrMessage, friendlyMessage = null) {
                let errorMessage = '';
                let finalMessage = '';
                // err object
                if (errOrMessage.message) {
                    errorMessage = errOrMessage.message;
                } else {
                    // string
                    errorMessage = errOrMessage;
                }
                if (this.$config.isInProduction) {
                    finalMessage = friendlyMessage ?? this.defaultErrorMessage;
                } else {
                    finalMessage = `Error: ${errorMessage}`;
                    console.error(errOrMessage);
                }
                this.show(finalMessage, 'error', 'mdi-alert-circle-outline');
                this.$nuxt.$firebaseEvents.error(errOrMessage);
                this.$nuxt.$firebaseEvents.toastError(finalMessage);
            },
            show(message, colour = '', icon = '') {
                this.snackBar = true;
                this.snackText = message;
                this.snackColour = colour;
                this.snackIcon = icon;
            }
        }
    };
</script>
