<template>
    <v-app id="inspire">
        <v-app-bar app>
            <v-container class="py-0 fill-height">
                <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer" />
                <v-btn depressed to="/">
                    <img
                        v-if="$vuetify.breakpoint.xs"
                        src="/assets/img/roboshadow-icon-toolbar.png"
                        style="max-width: 36px"
                        alt="RoboShadow"
                    />
                    <LogoRoboShadow v-else />
                </v-btn>
                <div class="hidden-md-and-down">
                    <v-btn text to="https://www.roboshadow.com" target="_blank">Home</v-btn>
                    <!--
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                Products
                                <v-icon small>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title>Network Scanner - Mobile App</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    -->
                    <v-btn text href="https://www.roboshadow.com/blog" target="_blank">Blog</v-btn>
                    <v-btn text href="https://www.roboshadow.com/about" target="_blank">
                        About
                    </v-btn>
                    <v-btn text href="https://www.roboshadow.com/contact" target="_blank">
                        Contact
                    </v-btn>
                </div>
                <v-spacer />
                <template v-if="user">
                    <v-btn color="success" to="/" outlined>Dashboard</v-btn>
                </template>
                <template v-else>
                    <!--
                    <v-btn
                        color="primary"
                        text
                        href="mailto:hello@roboshadow.com"
                        class="hidden-sm-and-down mr-2"
                    >
                        Get Support
                    </v-btn>
                    -->
                    <v-btn
                        color="success"
                        to="/login#register"
                        class="hidden-sm-and-down mr-2"
                        outlined
                    >
                        FREE Account
                    </v-btn>
                    <v-btn color="success" to="/login">Log In</v-btn>
                </template>
            </v-container>
        </v-app-bar>
        <!--<ContactBar />
        <Navbar />-->
        <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" v-model="drawer" app clipped>
            <v-list dense>
                <v-list-item-group>
                    <v-list-item to="/home" link>
                        <v-list-item-action>
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Home</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group no-action prepend-icon="mdi-table" :value="true">
                        <template v-slot:activator>
                            <v-list-item-title>Products</v-list-item-title>
                        </template>
                        <v-list-item class="pl-10" to="/app" link>
                            <v-list-item-action>
                                <v-icon>mdi-android</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Network Scanner App</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item to="/blog" link>
                        <v-list-item-action>
                            <v-icon>mdi-post</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Blog</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/about" link>
                        <v-list-item-action>
                            <v-icon>mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>About</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/contact" link>
                        <v-list-item-action>
                            <v-icon>mdi-email</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Contact</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item to="/support" link>
                        <v-list-item-action>
                            <v-icon>mdi-face-agent</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Get Support</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/register" link>
                        <v-list-item-action>
                            <v-icon>mdi-account-plus</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>FREE Account</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/login" link>
                        <v-list-item-action>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Login</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-main app>
            <v-container fluid>
                <v-responsive>
                    <nuxt />
                </v-responsive>
            </v-container>
        </v-main>
        <v-footer app absolute>
            <v-container>
                <span class="text-caption">
                    &copy;
                    <span v-if="!$vuetify.breakpoint.xs">2017 - </span>
                    {{ new Date().getFullYear() }} RoboShadow Ltd. v1.10
                </span>
                &nbsp;&nbsp;&nbsp;
                <!-- <a href="/app" class="text-caption" style="color: white">
                </a> -->
                <img
                    width="18"
                    src="/assets/img/app/icons/android_robot_green.png"
                    alt="Get the android app on google play"
                    style="vertical-align: top"
                />
                Get the App
            </v-container>
        </v-footer>
    </v-app>
</template>

<noscript>
    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=496388&fmt=gif" />
</noscript>

<script>
    import constants from '~/plugins/constants';
    // import Navbar from '@/components/Navbar.vue';
    // import ContactBar from '@/components/ContactBar.vue';
    export default {
        components: {
            // Navbar,
            // ContactBar
        },
        data() {
            return {
                drawer: null,
                user: null
            };
        },
        computed: {
            isSuperSmall() {
                return this.$vuetify.breakpoint.width < 550;
            }
        },
        created() {
            this.user = this.$fire.auth.currentUser;
            this.$userSettingsManager.initialiseTheme();
        },
        mounted() {
            this.$configureGoogleTag(this);
            if (this.$config.isInProduction) this.$hotjarTrackingService.addHotjarScript();
            if (this.$config.isInProduction) this.$linkedInTracking.addLinkedInScript();
        },
        head() {
            return this.$getPageHeadTwitter(constants.SITE_NAME, constants.SITE_STRAP_LINE);
        }
    };
</script>
