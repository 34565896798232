<template>
    <v-footer app absolute>
        <v-container class="py-0">
            <span class="text-caption pl-2">
                &copy;
                <span v-if="!$vuetify.breakpoint.xs">2017 - </span>
                {{ new Date().getFullYear() }} RoboShadow Ltd.
                <span>v{{ versionNumber }}.{{ buildNumber }}</span>
            </span>
            &nbsp;&nbsp;&nbsp;
            <a href="/app" :style="`color: ${monoChromeColour}`">
                <img
                    width="18"
                    src="/assets/img/app/icons/android_robot_green.png"
                    alt="Get the android app on google play"
                    style="vertical-align: middle"
                />
                <span class="text-caption">Get the App</span>
            </a>
        </v-container>
    </v-footer>
</template>

<script>
    export default {
        data() {
            return {
                buildNumber: process.env.buildNumber,
                versionNumber: process.env.versionNumber
            };
        },
        computed: {
            monoChromeColour() {
                return this.$vuetify.theme.dark ? 'white' : 'black';
            }
        }
    };
</script>
